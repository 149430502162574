import React from "react";
import { getManu, npnEntrySaveAction } from "../../redux/actions";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
const Footer = () => {
	const handleSocalClick = (type) => {
		npnEntrySaveAction({ "formType": "SOCIAL_MEDIA_CLICK", media: type })
			.then((responseData) => {
			})
			.catch((err) => {
				console.log("Response Error:", JSON.stringify(err));
			});
	};

	return (
		<footer role="contentinfo">
			<Container className="footer">
				<div className="footer-link " aria-label="footerMenu">
					<ul>
						<li className="fw-bold uppercase">
							<div className="flex flex-row my-2 lg:my-0 p-0 lg:px-3">
								<a href="https://www.facebook.com/Toro.Company" target="_blank" rel="noreferrer" onClick={() => { handleSocalClick("facebook") }}>
									<svg fill="#ffffff" height="14" width="14" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
										<path
											id="XMLID_835_"
											d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064 c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996 V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545 C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703 c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"></path>
									</svg>
								</a>
								<a href="https://twitter.com/TheToroCompany" target="_blank" rel="noreferrer" onClick={() => { handleSocalClick("twitter") }} className="px-2 hover:scale-125">
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16" class="bi bi-twitter-x">
										<path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"></path>
									</svg>
								</a>
								<a href="https://www.youtube.com/channel/UCQd20DNP_mT_PMUr-6z2E-g" target="_blank" rel="noreferrer" onClick={() => { handleSocalClick("youtube") }}>
									<svg fill="#ffffff" height="14" width="14" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310" stroke="#ffffff">
										<path
											id="XMLID_823_"
											d="M297.917,64.645c-11.19-13.302-31.85-18.728-71.306-18.728H83.386c-40.359,0-61.369,5.776-72.517,19.938 C0,79.663,0,100.008,0,128.166v53.669c0,54.551,12.896,82.248,83.386,82.248h143.226c34.216,0,53.176-4.788,65.442-16.527 C304.633,235.518,310,215.863,310,181.835v-53.669C310,98.471,309.159,78.006,297.917,64.645z M199.021,162.41l-65.038,33.991 c-1.454,0.76-3.044,1.137-4.632,1.137c-1.798,0-3.592-0.484-5.181-1.446c-2.992-1.813-4.819-5.056-4.819-8.554v-67.764 c0-3.492,1.822-6.732,4.808-8.546c2.987-1.814,6.702-1.938,9.801-0.328l65.038,33.772c3.309,1.718,5.387,5.134,5.392,8.861 C204.394,157.263,202.325,160.684,199.021,162.41z"></path>{" "}
									</svg>
								</a>
								<a href="https://www.instagram.com/thetorocompany/" target="_blank" rel="noreferrer" onClick={() => { handleSocalClick("instagram") }}>
									<svg height="20" width="20" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.04 239.04" fill="#000000" stroke="#000000">
										<path
											style={{ fill: "#FFFFFF" }}
											d="M167.809,36.555c-22.276-0.183-44.597-1.054-66.919-0.504c-25.851,0.642-46.018,2.2-61.281,22.872 c-3.988,9.854-6.004,21.359-6.967,33.368c-0.183,3.163-0.367,6.417-0.596,9.763c-0.825,20.396,0.55,41.435,0.092,58.118 c2.154,10.955,6.371,20.809,14.255,28.326c26.126,24.888,79.294,17.142,114.128,15.767c16.73-6.096,30.663-11.138,38.134-30.251 c5.913-15.034,4.95-34.193,5.363-50.326C204.797,90.502,206.264,44.714,167.809,36.555z"></path>{" "}
										<path d="M157.588,48.38c44.505,5.729,31.443,70.219,30.205,101.478c-0.688,17.188-3.758,30.48-21.496,36.438 c-23.33,7.838-57.706,5.225-81.494,1.742c-15.905-2.292-28.372-7.059-32.772-23.559c-6.967-26.08-4.996-61.373-1.971-87.957 c0.092-0.504,0.229-0.871,0.458-1.192C50.93,35.547,134.441,45.401,157.588,48.38z"></path>{" "}
										<path style={{ fill: "#FFFFFF" }} d="M176.059,72.627c0.092-10.955-13.567-14.438-20.901-7.7c-1.971,1.833-2.888,4.629-3.025,7.654 c0.733,3.896,3.071,7.746,6.096,9.946C165.334,87.523,175.967,80.877,176.059,72.627z"></path>{" "}
										<path style={{ fill: "#FFFFFF" }} d="M155.754,93.94c-15.675-23.742-51.06-26.08-70.081-5.042c-12.971,14.346-13.888,38.226-4.125,55.185 c0.55,0.183,1.054,0.504,1.513,1.054c15.859,17.692,43.176,24.109,62.794,8.067C163.363,138.858,168.496,113.236,155.754,93.94z"></path>{" "}
										<path d="M93.282,126.483c5.546,15.584,25.255,27.959,40.976,18.334c31.763-19.434-5.546-72.235-31.718-48.814 C93.282,101.457,89.661,116.261,93.282,126.483z"></path>{" "}
									</svg>
								</a>
							</div>
						</li>
						<li className="fw-bold uppercase">
							<a href="/support" target="new">
								Support
							</a>
						</li>
						<li className="fw-bold uppercase">
							<a href="/public/Updated_TOLCRP_Terms_and_Conditions_2024.pdf" target="new" rel="noreferrer">
								PROGRAM TERMS AND CONDITIONS
							</a>
						</li>
						<li className="fw-bold uppercase">
							<a href="https://www.toro.com/en-gb" target="new">
								toro
							</a>
						</li>
						<li className="fw-bold uppercase">
							<a href="https://www.irritrol.com/" target="new">
								IRRITROL
							</a>
						</li>

						<li className="uppercase fw-bold">
							<a href="https://www.uniquelighting.com/" target="new">
								UNIQUE LIGHTING
							</a>
						</li>
						<li className="fw-bold uppercase">© THE TORO COMPANY. ALL RIGHTS RESERVED.</li>
					</ul>
				</div>
			</Container>
		</footer>
	);
};
export default connect(null, { getManu, npnEntrySaveAction })(Footer);

import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NotLoggedIn from "./NotLoggedIn";
const Home = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector((state) => state.user);
	const config = useSelector((state) => state.app.config);
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<NotLoggedIn />
		</>
	);
};

export default IsLoadingHOC(Home, "");

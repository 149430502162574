import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { getUserDetails, updateUserDetails, getLockupAction, updateUserFnameAction } from "../../redux/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import GooglePlaces from "../../components/GooglePlaces";
import InputMask from "react-input-mask";
//import Calendar from "react-calendar";
import Required from "../../components/Required";
var validator = require("validator");

const Profile = ({ getUserDetails, updateUserDetails, setLoading }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	let [companyList, setCompanyList] = useState([]);
	const history = useNavigate();
	const [place, setPlace] = useState({});
	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		birth: "",
		cellPhone: "",
		address: "",
		state: "",
		suite: "",
		city: "",
		zip: "",
		companyName: "",
		socialSecurityNumber: "",
		latitude: "",
		longitude: "",
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", cellPhone: "", lastName: "", address: "", state: "", suite: "", zip: "", city: "", socialSecurityNumber: "", companyName: "" });
	//const [calender, setCalender] = useState(false);
	const errorMessages = {
		firstName: { reqMsg: "Please enter your first name.", invalidMsg: "First Name must be  maximum 254  characters long and must contain alphabets and special characters only." },
		lastName: { reqMsg: "Please enter your last name.", invalidMsg: "Last Name must be 254 characters long and must contain alphabets and special characters only." },
		email: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		confirmEmail: { reqMsg: "Please enter a valid email address.", invalidMsg: "Please enter a valid email address." },
		city: { reqMsg: "Please enter your city.", invalidMsg: "Please enter a valid city name." },
		address: { reqMsg: "Please enter your address.", invalidMsg: "Please enter a valid address." },
		suite: { reqMsg: "Please enter your SUITE/APT. NO.", invalidMsg: "Please enter a valid SUITE/APT. NO." },
		state: { reqMsg: "Please enter your state.", invalidMsg: "Please enter a valid state." },
		zip: { reqMsg: "Please enter your zip code.", invalidMsg: "Please enter a valid zip code." },
		cellPhone: { reqMsg: "Please enter valid phone number.", invalidMsg: "Please enter valid phone number." },
		birth: { reqMsg: "Please enter your valid DOB.", invalidMsg: "Please enter your valid DOB" },
		companyName: { reqMsg: "Please select Company.", invalidMsg: "Please select Company" },
		socialSecurityNumber: { reqMsg: "Please enter a valid social security number.", invalidMsg: "Please enter a valid social security number" },
	};
	useEffect(() => {
		let requestData = { lookups: ["COMPANY_LIST"] };
		getLockupAction(requestData).then((response) => {
			if (response.httpCode === 200 && response.data) {
				if (response.data.content) {
					let comListInd = response.data.content.findIndex((v) => v.name === "COMPANY_LIST");
					let comList = [];
					if (comListInd >= 0) {
						comList = response.data.content[comListInd].data;
						let dt = response.data.content[comListInd].data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
						setCompanyList(dt);
					}
				}
			}
		});
	}, []);
	useEffect(() => {
		setLoading(true);
		getUserDetails(user.id)
			.then((response) => {
				console.log("user details response", response);
				setLoading(false);
				let fData = {};
				for (let i in response.data) {
					if (response.data[i] !== null) {
						if (i === "birth") {
							let dt = new Date(response.data[i]);
							fData[i] = `${dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : `0${dt.getMonth() + 1}`}/${dt.getDate() > 9 ? dt.getDate() : `0${dt.getDate()}`}/${dt.getFullYear()}`;
						} else {
							fData[i] = response.data[i];
						}
					}
				}
				setData({ ...data, ...fData });
			})
			.catch((error) => {
				setLoading(false);
				console.log("error", error);
			});
		let items = {};
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				if (["address", "city", "zip", "state", "birth", "cellPhone", "socialSecurityNumber"].indexOf(element.name) >= 0) {
					element.validation.mandatory = true;
				}
				if (element.name === "username") {
					element.validation.maxLength = 200;
				}
				items[element.name] = element.validation;
			});
			setValidation(items);
		}

		// eslint-disable-next-line
	}, []);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		for (let key in validationArray) {
			if (key === "minLength" && value.length !== 0) {
				if (value.length < validationArray[key]) {
					errorMessage = t("Should not be less then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = t("Should not be greater then", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = t("Should not be empty");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = t("Please enter a valid email");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key] && value.length !== 0) {
				if (value.search(/[a-z]/) < 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				// eslint-disable-next-line
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = t("Should have atleast one special letter");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	const onSubmit = () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				if (!isValidDate(value)) {
					errorMessage = "Enter a valid date in MM/DD/YYYY format.";
				} else {
					errorMessage = errorMessages.birth ? errorMessages.birth.invalidMsg : `${t("Invalid")} ${t("Birth Date")}`;
				}
			} else if (name === "birth") {
				errorMessage = "";
			}
			if (name === "birth" && isValidDate(value)) {
				let dt = new Date("2006-08-31");
				if (dt < new Date(data.birth)) {
					errorMessage = "Age must be 16 years or more on 31 Aug 2022, to register with this offer.";
				}
			}
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("Please enter a valid email");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be less then", {
								count: validationArray[key],
						  });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("Should not be greater then", {
								count: validationArray[key],
						  });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should not be contain number");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("Should be contain number");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("Should not be empty");
				} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Upper Case latter");
				} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = t("Should have atleast one Lower Case latter");
				} else if (key === "hasNumeric" && validationArray[key] && value.search(/[0-9]/) < 0) {
					errorMessage = t("Should be contain number");
				} else if (key === "hasAlpha" && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
					errorMessage = t("Should be contain alphabets");
				} else if (key === "specialCharReq" && value.length !== 0) {
					var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
					if (!format.test(value)) {
						errorMessage = t("Should have atleast one special letter");
					}
				}
			}
			errorArray[name] = errorMessage;
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!check) {
			let requestData = { ...data };
			if (requestData.birth) {
				requestData.birth = formatDate(requestData.birth);
			}
			setLoading(true);
			updateUserDetails(user.id, requestData)
				.then((response) => {
					console.log("response", response);
					dispatch(updateUserFnameAction(requestData.firstName));
					toast.success(`Profile updated successfully.`);
					setTimeout(() => {
						history.push("/");
					}, 1000);

					setLoading(false);
				})
				.catch((error) => {
					//console.log(error);
					if (Array.isArray(error)) {
						error.forEach((item) => {
							toast.error(item.message);
						});
					} else {
						toast.error(t("Something went wrong."));
					}
					setLoading(false);
				});
		}
	};

	const formatDate = (value) => {
		value = new Date(value);
		const dd = String(value.getDate()).padStart(2, "0");
		const mm = String(value.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = value.getFullYear();
		const formatedDate = yyyy + "-" + mm + "-" + dd;
		return formatedDate;
	};
	const isValidDate = (date) => {
		// eslint-disable-next-line
		const matches = /^(\d{1,2})[/\/](\d{1,2})[/\/](\d{4})$/.exec(date);
		if (matches === null) {
			return false;
		}
		const [_, m, d, y] = matches;
		const composedDate = new Date(+y, +m - 1, +d);
		return composedDate.getDate() === +d && composedDate.getMonth() === +m - 1 && composedDate.getFullYear() === +y;
	};
	const handleDate = (value) => {
		//const formatedDate = formatDate(value);
		//setCalender(false);
		setData({ ...data, birth: value });
	};
	const formatChars = {
		"1": "[12]",
		"0": "[0123456789]",
	};

	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let err = { ...error };
			let curStateData = Object.assign({}, data);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
				err.address = "";
			}
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
				err.state = "";
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
				err.city = "";
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
				err.suite = "";
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
				err.zip = "";
			}
			if (curStateData.hasOwnProperty("latitude")) {
				curStateData.latitude = place.latitude;
			}
			if (curStateData.hasOwnProperty("longitude")) {
				curStateData.longitude = place.longitude;
			}
			setData(curStateData);
			setError(err);
		}
		// eslint-disable-next-line
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	const tooltip = <Tooltip id="tooltip">Please type in your mailing address and select the proper address from the dropdown.</Tooltip>;
	const ssnTooltip = <Tooltip id="tooltip">This is required for 1099 filings in the USA if you earn $600 or more in a calendar year.</Tooltip>;
	return (
		<Container>
			<div className="profile-page">
				<form role="form" aria-label="Profile Update">
					<Row xs={1} md={1} lg={1}>
						<h1 className="mb-5 mt-5">{t("Manage Your Account")}</h1>
					</Row>
					<Row className="mt-3 mb-5 page-tab_nav">
						<Col className="text-left">
							<Link id="href-point-history" to="/points-history">
								{t("Point History")}
							</Link>
						</Col>
						<Col className="text-center">
							<Link id="href-my-rewards" to="/my-rewards">
								{t("My Rewards")}
							</Link>
						</Col>
						<Col className="text-right">
							<Link id="href-profile" to="/profile" className="active-page">
								{t("Profile")}
							</Link>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							{/*[START:FIRSTNAME]*/}
							<div className="form-group row theme-profile-firstName">
								<label htmlFor="first-name" className="col-sm-12 col-form-label profile-firstName-label">
									<Trans>lang_key_fname</Trans>
									{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" data-test="profile-firstName" name="firstName" className="profile-firstName form-control" value={data.firstName !== null ? data.firstName : ""} onChange={handleChange} id="first-name" placeholder={t("lang_key_fname")} />
									<div className="input-error">{error.firstName}</div>
								</div>
							</div>
							{/*[END:FIRSTNAME]*/}
						</Col>
						<Col sm={6}>
							{/*[START:LASTNAME]*/}
							<div className="form-group row theme-profile-lastName">
								<label htmlFor="last-name" className="col-sm-12 col-form-label profile-lastName-label">
									<Trans>lang_key_lname</Trans>
									{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" data-test="profile-lasttName" name="lastName" className="profile-lastName form-control" value={data.lastName !== null ? data.lastName : ""} onChange={handleChange} id="last-name" placeholder={t("lang_key_lname")} />
									<div className="input-error">{error.lastName}</div>
								</div>
							</div>
							{/*[END:LASTNAME]*/}
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							{/*[START:EMAIL]*/}
							<div className="form-group row theme-profile-email">
								<label htmlFor="email" className="col-sm-12 col-form-label">
									<Trans>lang_key_email</Trans>
									{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="email" data-test="profile-email" name="email" className="profileEmail form-control" value={data.email} onChange={handleChange} id="email" placeholder={t("lang_key_email")} />
									<div className="input-error">{error.email}</div>
								</div>
							</div>
							{/*[END:EMAIL]*/}
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							{/*[START:COMPANYNAME]*/}

							<div className="form-group row theme-profile-companyName">
								<label htmlFor="companyName" className="col-sm-12  col-form-label">
									<Trans>Company Name</Trans>
									{validation["companyName"] && validation["companyName"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<select disabled={true} data-test="signup-companyName" value={data.companyName} name="companyName" className="form-control control-disabled" id="companyName" placeholder={t("Company Name")}>
										<option value="">Select Company</option>
										{companyList.map((v, i) => {
											return (
												<option key={`com-opt-${i}`} value={v.id}>
													{v.name}
												</option>
											);
										})}
									</select>
									<span className="input-error">{error.companyName}</span>
								</div>
							</div>
							{/*[END:COMPANYNAME]*/}
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<div className="form-group row theme-signup-address-finder">
								<div className="custom-tooltip-wrap col-sm-12 ">
									<label htmlFor="addressFinder" className="col-form-label">
										<Trans>lang_key_select_address</Trans>
										{validation["address"] && validation["address"].mandatory ? <Required /> : ""}
									</label>
									<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={tooltip}>
										<Button className="default">?</Button>
									</OverlayTrigger>
								</div>
								<div className="col-sm-12">
									<GooglePlaces value={data.address} getPlaceHandler={googleSelectedAddressHandler} placeholder={t("lang_key_select_address")} />
									<div className="input-error">{error.address}</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							{/*[START:SUITE]*/}
							<div className="form-group row theme-profile-suite">
								<label htmlFor="suite" className="col-sm-12 col-form-label">
									<Trans>lang_key_add_suite</Trans>
									{validation["suite"] && validation["suite"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" data-test="profile-suite" name="suite" onChange={handleChange} value={data.suite ? data.suite : ""} className="form-control" id="suite" placeholder={t("lang_key_add_suite")} />
									<div className="input-error">{error.suite}</div>
								</div>
							</div>
							{/*[END:SUITE]*/}
						</Col>
						<Col sm={6}>
							{/*[START:CITY]*/}
							<div className="form-group row theme-profile-city">
								<label htmlFor="city" className="col-sm-12 col-form-label">
									<Trans>City</Trans>
									{validation["city"] && validation["city"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" data-test="profile-city" name="city" onChange={handleChange} value={data.city ? data.city : ""} className="form-control" id="city" placeholder={t("City")} />
									<div className="input-error">{error.city}</div>
								</div>
							</div>
							{/*[END:CITY]*/}
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							{/*[START:STATE]*/}
							<div className="form-group row theme-profile-state">
								<label htmlFor="state" className="col-sm-12 col-form-label">
									{t("lang_key_add_state")}
									{validation["state"] && validation["state"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" data-test="profile-state" name="state" onChange={handleChange} value={data.state ? data.state : ""} className="form-control" id="state" placeholder={t("lang_key_add_state")} />
									<div className="input-error">{error.state}</div>
								</div>
							</div>
							{/*[END:STATE]*/}
						</Col>
						<Col sm={6}>
							{/*[START:ZIP]*/}
							<div className="form-group row theme-profile-zip">
								<label htmlFor="zip" className="col-sm-12 col-form-label">
									<Trans>lang_key_add_zip</Trans>
									{validation["zip"] && validation["zip"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" data-test="profile-zip" name="zip" onChange={handleChange} value={data.zip ? data.zip : ""} className="form-control" id="zip" placeholder={t("Zip")} />
									<div className="input-error">{error.zip}</div>
								</div>
							</div>
							{/*[END:ZIP]*/}
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							{/*[START:BIRTH]*/}
							<div className="form-group row theme-profile-birth">
								<div className="col-sm-12 custom-tooltip-wrap ">
									<label htmlFor="dobenter" className="col-form-label">
										<Trans>Birth Date</Trans>
										{validation["birth"] && validation["birth"].mandatory ? <Required /> : ""}
									</label>
									<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={ssnTooltip}>
										<Button className="default">?</Button>
									</OverlayTrigger>
								</div>
								<div className="col-sm-12">
									<InputMask
										className="form-control show-placegholder"
										mask="00/00/1000"
										maskChar={""}
										value={data.birth}
										id="dobenter"
										formatChars={formatChars}
										onChange={(e) => {
											handleDate(e.target.value);
										}}
										placeholder="MM/DD/YYYY"></InputMask>
									<span className="input-error">{error.birth}</span>
								</div>
							</div>
							{/*[END:BIRTH]*/}
						</Col>
						<Col sm={6}>
							{/*[START:CELLPHONE]*/}
							<div className="form-group row theme-profile-homePhone">
								<label htmlFor="cellPhone" className="col-sm-12 col-form-label">
									<Trans>Phone Number</Trans>
									{validation["cellPhone"] && validation["cellPhone"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="number" data-test="profile-cellPhone" name="cellPhone" onChange={handleChange} value={data.cellPhone ? data.cellPhone : ""} className="form-control" id="cellPhone" placeholder={t("Phone Number")} />
									<div className="input-error">{error.cellPhone}</div>
								</div>
							</div>
							{/*[END:CELLPHONE]*/}
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							{/*[START:SSNUMBER]*/}
							<div className="form-group  theme-signup-lastname">
								<div className="custom-tooltip-wrap ">
									<label htmlFor="socialSecurityNumber" className=" col-form-label">
										{/* <Trans>Phone Number</Trans> */}
										<Trans>lang_key_SSN</Trans>
										{validation["socialSecurityNumber"] && validation["socialSecurityNumber"].mandatory ? <Required /> : ""}
									</label>
									<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={ssnTooltip}>
										<Button className="default">?</Button>
									</OverlayTrigger>
								</div>
								<div className="">
									<InputMask className="form-control control-disabled" disabled={true} mask="999-99-9999" maskChar={""} value={data.socialSecurityNumber} id="socialSecurityNumber" name="socialSecurityNumber" onChange={handleChange} placeholder={t("lang_key_SSN")}></InputMask>
									<span className="input-error">{error.socialSecurityNumber}</span>
								</div>
							</div>
							{/*[END:SSNUMBER]*/}
						</Col>
						<Col sm={6}></Col>
					</Row>
					<Row>
						<Col sm={12}>
							<div className="submit text-center">
								<button type="button" id="profile-submit-btn" data-test="profile-submit" onClick={onSubmit} className="btn btn-primary">
									<Trans>Save</Trans>
								</button>
							</div>
						</Col>
					</Row>
				</form>
			</div>
		</Container>
	);
};

export default connect(null, { getUserDetails, updateUserDetails })(IsLoadingHOC(Profile));

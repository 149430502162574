import React from 'react';
import ReactGA from 'react-ga';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
const withRouter = Component => {
  function ComponentWithRouterProp(props) {
    // let location = useLocation();
    // let navigate = useNavigate();
    // let params = useParams();
    return <Component {...props} />;
  }

  return ComponentWithRouterProp;
};
const RouteChangeTracker = () => {
  ReactGA.set({ page: window.location.href });
  ReactGA.pageview(window.location.href);

  return <div></div>;
};

export default withRouter(RouteChangeTracker);

import React from "react";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { forgotPasswordAction } from "../../redux/actions";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
const ForgetPassword = (props) => {
	const history = useNavigate();
	const { t, i18n } = useTranslation();
	const handleChange = (e, setValue) => {
		const { name, value } = e.target;
		setValue({ [name]: value });
	};
	return (
		<>
			<div id="enternow" className="register-page signup-form">
				<div className="py-4 my-5 col-sm-10">
					<div className="my-5 d-flex flex-column align-items-center justify-content-center">
						<h1 className="h2 custom-home-h1 d-inline px-1">{t("forgot_password")}</h1>
					</div>
					<div className="container-fluid">
						<Formik
							initialValues={{ email: "" }}
							validationSchema={Yup.object({
								email: Yup.string()
									.email(t("REGISTER.ERROR.EMAIL_INVALID_MSG"))
									.required(t("REGISTER.ERROR.EMAIL_REQ_MSG")),
							})}
							handleChange={handleChange}
							onSubmit={(values, { setSubmitting }) => {
								props.setLoading(true);
								forgotPasswordAction(values.email)
									.then((response) => {
										setSubmitting(false);
										props.setLoading(false);
										if (response.httpCode >= 200 && response.httpCode <= 299) {
											toast.success(t("REGISTER.ERROR.FORGOT_PASS_SUCCESS_EMAIL"));
											history.push("/");
										} else if (response.httpCode === 404) {
											toast.error(t("REGISTER.ERROR.FORGOT_PASS_404_EMAIL"));
										} else {
											toast.error(t("COMMON.SOMTHING_WRONG"));
										}
									})
									.catch((error) => {
										console.log(error);
										setSubmitting(false);
										props.setLoading(false);
										error.forEach((item) => {
											if (item.code === "NOT FOUND") {
												toast.error("Please provide your registered email and try again.");
											} else {
												toast.error(item.message);
											}
										});
									});
							}}>
							{(props) => (
								<form onSubmit={props.handleSubmit}>
									<div className="row justify-content-center">
										<div className="col-md-12">
											<TextField
												InputProps={{
													autoComplete: "true",
												}}
												id={`${t("lang_key_email")}`}
												fullWidth
												label={`${t("lang_key_email")}`}
												data-test="sign-in-username-input"
												name="email"
												onChange={(e) => handleChange(e, props.setValues)}
												error={props?.errors?.email ? true : false}
												helperText={props?.errors?.email || ""}
												value={props.values.email}
												variant="outlined"
											/>
										</div>
									</div>

									<div className="row justify-content-center">
										<div className="mt-5 w-100">
											<button data-test="reset-password-button" type="submit" className="btn btn-primary login-btn mt-0 uppercase">
												{t("SUPPORT.SUBMIT.LABEL")}
											</button>
										</div>
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
};

export default IsLoadingHOC(ForgetPassword, "Wait ....");

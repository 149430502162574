import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect, useSelector } from "react-redux";
import { login } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import SecondFactorAuth from "../../components/SecondFactorAuth";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import Captcha from "../../components/Captcha";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

const Login = ({ setLoading, login }) => {
	const { t } = useTranslation();
	const history = useNavigate();
	const confige = useSelector((state) => state.app.config);
	const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
	const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
	const [errors, setErrors] = useState({ username: "", password: "", captchaVal: "" });
	const [fpData, setFpData] = useState({ visitorId: "", requestId: "" });
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	const [redirectUrl, setRedirectUrl] = useState("/");
	React.useEffect(() => {
		if (confige.fingerprinting) {
			if (confige.fingerprinting.enable) {
				const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
				// Get the visitor identifier when you need it.
				fpPromise
					.then((fp) => fp.get())
					.then((result) => {
						setFpData({ visitorId: result.visitorId, requestId: result.requestId });
					});
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.loginCaptcha) {
				setCaptchaEnable(true);
			}
		}
	}, [confige]);
	React.useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(errors));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
		}
		setErrors(errorArray);
		// eslint-disable-next-line
	}, [captchaValue]);
	React.useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		const redtUrl = query.get("route") ? query.get("route") : "/";
		if (redirectUrl !== redtUrl) {
			setRedirectUrl(redtUrl);
		}
		// eslint-disable-next-line
	}, [window.location]);

	const onSubmit = () => {
		setLoading(true);
		console.log(fpData);
		let requestBody = {};
		if (captchaEnable) {
			requestBody["captchaVal"] = captchaValue;
		}
		login(requestBody)
			.then((response) => {
				setLoading(false);
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				if (response.httpCode === 204) {
					setSecondFactorAuthData({ id: response.data });
					setShowSecondFactorPopup(true);
				} else {
					toast.success(t("LOGIN_SCREEN.USER_LOGIN_SUCC"));
					history.push(redirectUrl);
				}
			})
			.catch((error) => {
				console.log("Error in login", error);
				setResetCaptchaCnt(resetCaptchaCnt + 1);
				setCaptchaValue(false);
				if (error?.errors) {
					let err = error?.errors[0];
					if (err["errorCode"]) {
						toast.error(t(`LOGIN_SCREEN.${err["errorCode"]}`));
					} else {
						if (error["httpCode"] === 403) {
							toast.error(t("LOGIN_SCREEN.USER_SUSPEENDED"));
						} else if (error["httpCode"] === 406) {
							toast.error(t("LOGIN_SCREEN.USER_NOT_VERIFIED"));
						} else {
							toast.error(t("LOGIN_SCREEN.E_INVALID_CREDENTIALS"));
						}
					}
				} else {
					toast.error(t("LOGIN_SCREEN.E_INVALID_CREDENTIALS"));
				}
				setLoading(false);
			});
	};

	const validationSchema = Yup.object().shape({
		username: Yup.string()
			.email(t("REGISTER.ERROR.EMAIL_INVALID_MSG"))
			.required(t("REGISTER.ERROR.EMAIL_REQ_MSG")),
		password: Yup.string()
			.required(t("REGISTER.ERROR.PASS_REQ_MSG"))
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t("REGISTER.ERROR.PASS_INVALID_MSG")),
	});
	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log(values);
		},
	});
	console.log(formik.errors);
	return (
		<div className="container-fluid mb-5">
			<div className="row justify-content-center">
				<div className="col-12 m-0 p-0">
					<img src="/public/images/Login_banner.png" alt="login-banner" className="d-block w-100" />
				</div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-10 col-xs-12">
					<form aria-label="login">
						<div className="my-5 d-flex flex-column align-items-center justify-content-center">
							<h1 className="h2 custom-home-h1 d-inline px-1">SIGN IN</h1>
							<p className="mt-4 fs-16 light-blue">USE YOUR EMAIL AND PASSWORD</p>
						</div>
						<div className="form-group row theme-form-email">
							<div className="col">
								<TextField
									InputProps={{
										autoComplete: "true",
									}}
									id={`${t("lang_key_email")}`}
									fullWidth
									label={`${t("lang_key_email")}`}
									data-test="sign-in-username-input"
									name="username"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik?.errors?.username ? true : false}
									helperText={formik?.errors?.username || ""}
									value={formik.values.username}
									variant="outlined"
								/>
							</div>
						</div>
						{/*[START:PASSWORD]*/}
						<div className="form-group row theme-form-password">
							<div className="col">
								<TextField
									InputProps={{
										autoComplete: "true",
									}}
									variant="outlined"
									fullWidth
									type="password"
									data-test="sign-in-password-input"
									name="password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									error={formik?.errors?.password ? true : false}
									helperText={formik?.errors?.password || ""}
									id={t("password")}
									label={t("password")}
									onKeyPress={(e) => e.key === "Enter" && onSubmit()}
								/>
							</div>
						</div>

						{/*[END:PASSWORD]*/}
						{/*[START:GOOGLECAPTCHA]*/}
						{captchaEnable ? (
							<div className="form-group row justify-content-center">
								<div className="col">
									{/* <label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
										Google captcha
									</label> */}
									<Captcha align="left" reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
									{/* {errorHtml(errors.captchaVal)} */}
									<div className="input-error">{t(errors.captchaVal)}</div>
								</div>
							</div>
						) : (
							""
						)}
						{/*[END:GOOGLECAPTCHA]*/}

						<div className="form-group row justify-content-center">
							<div className="col-12 d-flex align-items-center justify-content-start my-5">
								<button onClick={formik.handleSubmit} data-test="sign-in-sign-in-button" type="button" className="btn btn-primary login-btn  mt-0 uppercase">
									<Trans>login</Trans>
								</button>
							</div>
							<div className="col-12">
								<a className="mb-3 text-decoration-none active-light-blue d-inline-block" href="/forget-password">
									<Trans>forgot_password</Trans>
								</a>
							</div>
							<div className="col-12">
								<a className="mb-3 text-decoration-none active-light-blue d-inline-block" href="/register">
									NEW USER? REGISTER NOW
								</a>
							</div>
						</div>
					</form>
				</div>
			</div>
			<Modal show={showSecondFactorPopup}>
				<Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ""}</Modal.Body>
			</Modal>
		</div>
	);
};

export default connect(null, { login })(IsLoadingHOC(Login));

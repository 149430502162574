import React, { useState, useEffect } from "react";
//import Menu from "../MicrositeMenu";
import Menu from "../Menu";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
import { Fragment } from "react";

const Header = ({ getManu }) => {
	const [menu, setMenu] = useState([]);
	useEffect(() => {
		getManu().then((response) => {
			setMenu(response.body.menuStructure);
		});
	}, [getManu]);

	return (
		<Fragment>
			<Menu data={menu} />
		</Fragment>
	);
};

export default connect(null, { getManu })(Header);

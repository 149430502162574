import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
//import Signup from "../screen/Signup";
import Register from "../components/Signup";
//import Home from "../screen/Home/MicroSiteHome";
import Home from "../screen/Home";
import Login from "../screen/Login";
import AboutUs from "../screen/Static/AboutUs";
import ContactUs from "../screen/Static/ContactUs";
import PrivatePocily from "../screen/Static/PrivatePolicy";
import ForgetPassword from "../screen/Auth/ForgetPassword";
import ResetPassword from "../screen/Auth/ResetPassword";
import ConfirmAccount from "../screen/Auth/ConfirmAccount";
import EarnPointsPreview from "../screen/Earnpoints/EarnPointsPreview";
import PointsHistory from "../screen/PointsHistory";
import PrivateRoute from "./PrivateRoute";
import Profile from "../screen/Profile";
import QuizThankuLandingPage from "../screen/Earnpoints/QuizThankuLandingPage";
import UploadReceipt from "../screen/ValidatePurchase/UploadReceipt";
import ValidateCode from "../screen/ValidatePurchase/ValidateCode";
import EligibleProduct from "../screen/Product/EligibleProduct";
import Rewards from "../screen/Rewards";
import RewardsPreview from "../screen/Rewards/RewardsPreview";
import RewardDownload from "../screen/Rewards/RewardDownload";
import MyRewards from "../components/rewards/MyRewards";
import EarnPoints from "../screen/Earnpoints/EarnPoints";
import HelpDesk from "../screen/HelpDesk/HelpDesk";
import HelpDeskTicket from "../screen/HelpDesk/HelpDeskTicket";
import ContestPreview from "../screen/Contest/ContestPreview";
import Contest from "../screen/Contest";
import ContestXPreview from "../screen/Contest/ContestXPreview";
import Upcoming from "../screen/CampaignStatus/Upcoming";
import Expired from "../screen/CampaignStatus/Expired";
import SupportScreen from "../screen/SupportScreen";
import Faq from "../components/FrequentlyAskedQuestions";
import PageNotFound from "../screen/Home/SubmissionExpired";

const routes = (
	<>
		<Routes>
			<Route path="/" element={<Navigate to="/home" />} />
			<Route exact path="/home" element={<AppRoute component={Home} layout={PublicLayout} />} />
			<Route exact path="/register" element={<AppRoute component={Register} layout={PublicLayout} />} />
			<Route exact path="/login" element={<AppRoute component={Login} layout={PublicLayout} />} />
			<Route exact path="/about-us" element={<AppRoute component={AboutUs} layout={PublicLayout} />} />
			<Route exact path="/contact-us" element={<AppRoute component={ContactUs} layout={PublicLayout} />} />
			<Route exact path="/private-policy" element={<AppRoute component={PrivatePocily} layout={PublicLayout} />} />
			<Route exact path="/forget-password" element={<AppRoute component={ForgetPassword} layout={PublicLayout} />} />
			<Route exact path="/reset-password" element={<AppRoute component={ResetPassword} layout={PublicLayout} />} />
			<Route exact path="/confirm-account" element={<AppRoute component={ConfirmAccount} layout={PublicLayout} />} />
			<Route exact path="/support" element={<AppRoute component={SupportScreen} layout={PublicLayout} />} />
			<Route exact path="/faq" element={<AppRoute component={Faq} layout={PublicLayout} />} />
			<Route exact path="/points-history" element={<PrivateRoute component={PointsHistory} layout={PublicLayout} />} />
			<Route exact path="/earnpoints" element={<PrivateRoute component={EarnPoints} layout={PublicLayout} />} />
			<Route exact path="/earnpoints-action-preview/:id" element={<PrivateRoute component={EarnPointsPreview} layout={PublicLayout} />} />
			<Route exact path="/profile" element={<PrivateRoute component={Profile} layout={PublicLayout} />} />
			<Route exact path="/upload-receipt" element={<PrivateRoute component={UploadReceipt} layout={PublicLayout} />} />
			<Route exact path="/validate-code" element={<PrivateRoute component={ValidateCode} layout={PublicLayout} />} />
			<Route exact path="/eligible-product" element={<PrivateRoute component={EligibleProduct} layout={PublicLayout} />} />
			<Route exact path="/rewards" element={<PrivateRoute component={Rewards} layout={PublicLayout} />} />
			<Route exact path="/reward-preview/:id" element={<PrivateRoute component={RewardsPreview} layout={PublicLayout} />} />
			<Route exact path="/my-rewards" element={<PrivateRoute component={MyRewards} layout={PublicLayout} />} />
			<Route exact path="/help-desk" element={<PrivateRoute component={HelpDesk} layout={PublicLayout} />} />
			<Route exact path="/help-desk/:id" element={<PrivateRoute component={HelpDeskTicket} layout={PublicLayout} />} />
			<Route exact path="/download/activity-sheet/:id/:filename" element={<PrivateRoute component={RewardDownload} layout={PublicLayout} />} />
			<Route exact path="/contest/:id" element={<PrivateRoute component={ContestPreview} layout={PublicLayout} />} />
			<Route exact path="/contest" element={<PrivateRoute component={Contest} layout={PublicLayout} />} />
			<Route exact path="/contestx/:id" element={<ContestXPreview />} />
			<Route exact path="/static/thank-you-quiz" element={<QuizThankuLandingPage />} layout={PublicLayout} />
			<Route exact path="/upcoming" element={<Upcoming />} />
			<Route exact path="/expired" element={<Expired />} />
			<Route path="*" element={<AppRoute component={PageNotFound} layout={PublicLayout} />} />
		</Routes>
	</>
);
export default routes;

import React from "react";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { Container } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
const NotLoggedIn = (props) => {
	const navigate = useNavigate();
	const { state } = useLocation();
	React.useEffect(() => {
		if (state?.section === "rewards") {
			setTimeout(() => {
				window.scrollTo(0, document.body.scrollHeight);
			}, 100);
		}
	}, [state]);

	return (
		<Container fluid>
			<div className="row justify-content-center my-5">
				<div className="col-12 d-flex justify-content-center">
					<h1 className="mt-3 h2 custom-home-h1 d-inline">HOW IT WORKS</h1>
				</div>
				<div className="col-12 d-flex flex-column flex-lg-row justify-content-center image-container">
					<img src="/public/images/home/How_it_works_step1.jpg" alt="how to 01" />
					<img src="/public/images/home/How_it_works_step2.png" alt="how to 02" />
					<img src="/public/images/home/How_it_works_step3.jpg" alt="how to 03" />
				</div>
			</div>
			{/* <div className="row justify-content-center video-container">
				<div className="col-10">
					<video controls className="w-100">
						<source src="/public/images/theonecontractor.mp4" type="video/mp4" />
					</video>
				</div>
			</div> */}
			<div className="row justify-content-center my-5 pb-5" id="rewards-content">
				<div className="col-12  d-flex justify-content-center">
					<h2 className="mt-3 h2 custom-home-h1 d-inline">REWARDS</h2>
				</div>
				<div className="col-12 row mt-3 reward-card-container g-5 mb-5">
					<img
						src="/public/images/home/Digital_rewards.jpg"
						alt="Digital Rewards"
						onClick={() => {
							navigate("/rewards/digital");
							return;
						}}
						className="col-lg-3"
					/>
					<img
						src="/public/images/home/Equipment_rewards.jpg"
						alt="Equipment Rewards"
						onClick={() => {
							navigate("/rewards/equipment");
							return;
						}}
						className="col-lg-3"
					/>
					<img
						src="/public/images/home/Product_voucher.jpg"
						alt="Product Voucher"
						className="col-lg-3"
						onClick={() => {
							navigate("/rewards/vouchers");
							return;
						}}
					/>
					<img
						src="/public/images/home/Physical_rewards.jpg"
						alt="Physical Rewards"
						className="col-lg-3"
						onClick={() => {
							navigate("/rewards/physical");
							return;
						}}
					/>
				</div>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(NotLoggedIn, "");

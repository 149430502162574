import React from "react";
import { Fragment } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
//import { saveSelectedMenu } from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { notificationGetAction } from "../redux/actions";
import { useNavigate } from "react-router-dom";
const Menu = ({ data }) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	const loadNotification = () => {
		const accessToken = user?.accessToken;
		if (accessToken) {
			dispatch(notificationGetAction(""));
		}
	};
	React.useEffect(() => {
		loadNotification();
	}, []);
	React.useEffect(() => {
		const interval = setInterval(() => {
			loadNotification();
		}, 300000);
		return () => clearInterval(interval);
		//eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<Navbar expand="xl" className="" sticky="top">
				<Container fluid className="px-4 py-3">
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Brand href="/">
						<img src="/public/images/torologo.png" alt="site logo" />
					</Navbar.Brand>
					<Navbar.Collapse id="basic-navbar-nav" className="ms-lg-5 ps-lg-3">
						<Nav className="me-auto">
							<Nav.Link
								onClick={() => {
									navigate("/home", { state: { section: "rewards" } });
								}}>
								REWARDS
							</Nav.Link>
							<Nav.Link href="/login">LOGIN</Nav.Link>
							<Nav.Link href="/register">REGISTER</Nav.Link>
							<Nav.Link href="/feature">FEATURE PRODUCT</Nav.Link>
							<Nav.Link href="/contract-resources">CONTRACTOR RESOURCES</Nav.Link>
							<Nav.Link href="https://thetorocompany.formstack.com/forms/summerpromo2024_copy">Mix & Match Submission </Nav.Link>
							<Nav.Link href="/support">SUPPORT</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Fragment>
	);
};

export default Menu;

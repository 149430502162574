import React, { useEffect, useState } from "react";
import Card from "./Card";
import { connect, useSelector } from "react-redux";
import { getContestAction } from "../redux/actions";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import IsLoadingHOC from "../components/IsLoadingHOC";
import { Navigate } from "react-router-dom";

const Contests = (props) => {
	const user = useSelector(({ user }) => user);
	const [data, setData] = useState([]);
	const [viewMore, setViewMore] = useState(true);
	const [page, setPage] = useState(0);
	const [pageRedirect, setPageRedirect] = useState(false);
	const pagesize = props.pagesize ? props.pagesize : 4;
	useEffect(() => {
		getNextData(0);
		// eslint-disable-next-line
	}, []);
	const getNextData = (page) => {
		props.setLoading(true);
		props
			.getContestAction(page + 1, pagesize)
			.then((response) => {
				let activityList = response.data;
				setData([...data, ...activityList]);
				setPage(page + 1);
				setViewMore(response.viewMore);
				props.setLoading(false);
			})
			.catch((ex) => {
				console.log("Error In API Call", ex);
				props.setLoading(false);
			});
	};
	const activityCLick = async (activityData, redirectUrl) => {
		try {
			setPageRedirect(redirectUrl);
		} catch (err) {
			console.log("Error in Activity status check", err);
			props.setLoading(false);
		}
	};
	if (pageRedirect) {
		return <Navigate push to={pageRedirect} />;
	} else {
		return (
			<Container fluid>
				<div className="portal-point-list-container">
					<Container fluid>
						<div className="label">CONTESTS</div>
					</Container>
					<Container fluid className="portal-point-list">
						<Row xs={1} md={2} lg={4}>
							{data.map((item, index) => {
								return (
									<Col key={index}>
										<Card>
											<div onClick={(e) => activityCLick(item, `/contest/${item.id}`)} className="activity-block">
												<img src={item.cardHeaderImage} alt="Avatar" style={{ width: "100%" }} />
												<h4 className="points-actions">Points: {item.pointRequired}</h4>
												<h4 className="title">{item.name}</h4>
												<div className="container-card-btn">
													<Button disabled={user.pointBalance < item.pointRequired ? true : false} activity-type={item.actionType} id={`spin-enter-btn-${item.id}`} className="cart-button btn btn-primary">
														{user.pointBalance < item.pointRequired ? <Trans>Unlock With More Points</Trans> : <Trans>Spin Now</Trans>}
													</Button>
												</div>
											</div>
										</Card>
									</Col>
								);
							})}
						</Row>
						<div className="text-center" style={{ width: "100%" }}>
							{viewMore && (
								<div className="text-center" style={{ width: "100%" }}>
									{" "}
									<Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => getNextData(page)}>
										<Trans>View More</Trans>
									</Button>{" "}
								</div>
							)}
						</div>
					</Container>
				</div>
			</Container>
		);
	}
};

export default connect(null, { getContestAction })(IsLoadingHOC(Contests, "Loading...."));

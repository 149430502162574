import React, { useState, useEffect } from "react";
// eslint-disable-next-line
import { signup, login, preRegistrationAction } from "../redux/actions";
import IsLoadingHOC from "./IsLoadingHOC";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Row } from "react-bootstrap";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import Captcha from "./Captcha";
const steps = ["Basic Details", "Company Details"];
var validator = require("validator");
const Signup = ({ signup, setLoading, login }) => {
	// const Signup = (props) => {
	const { t, i18n } = useTranslation();
	//const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;
	const confige = useSelector((state) => state.app.config);
	const history = useNavigate();
	const query = new URLSearchParams(window.location.search);
	const referralid = query.get("refCode") ? query.get("refCode") : "";
	const source = query.get("src") ? query.get("src") : "";
	const promoCode = query.get("promoCode") ? query.get("promoCode") : "";
	let [captchaValue, setCaptchaValue] = useState(false);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
	React.useEffect(() => {
		if ((confige?.captchaConfig?.recaptchaVersion || "").toLowerCase() === "v3") {
			const interval = setInterval(() => {
				setResetCaptchaCnt(resetCaptchaCnt + 1);
			}, 45000);
			return () => clearInterval(interval);
		}
	}, [resetCaptchaCnt, confige]);

	const termConditionChkHandler = (checked, name) => {
		let errorArray = JSON.parse(JSON.stringify(error));
		let d = JSON.parse(JSON.stringify(data));
		if (checked) {
			if (name === "agree_to_terms") {
				errorArray[name] = "";
			}
			d.additionalInfo[name] = true;
		} else {
			d.additionalInfo[name] = false;
			if (name === "agree_to_terms") {
				errorArray[name] = "REGISTER.ERROR.TNC_CHECKBOX";
			}
		}
		setError(errorArray);
		setData(d);
	};

	const errorMessages = {
		firstName: { reqMsg: "REGISTER.ERROR.FIRST_NAME_REQ_MSG", invalidMsg: "REGISTER.ERROR.FIRST_NAME_INVALID_MSG" },
		lastName: { reqMsg: "REGISTER.ERROR.LAST_NAME_REQ_MSG", invalidMsg: "REGISTER.ERROR.LAST_NAME_INVALID_MSG" },
		email: { reqMsg: "REGISTER.ERROR.EMAIL_REQ_MSG", invalidMsg: "REGISTER.ERROR.EMAIL_INVALID_MSG" },
		confirmEmail: { reqMsg: "REGISTER.ERROR.EMAIL_REQ_MSG", invalidMsg: "REGISTER.ERROR.EMAIL_INVALID_MSG" },
		//city: { reqMsg: "REGISTER.ERROR.CITY_REQ_MSG", invalidMsg: "REGISTER.ERROR.CITY_INVALID_MSG" },
		state: { reqMsg: "REGISTER.ERROR.STATE_REQ_MSG", invalidMsg: "REGISTER.ERROR.STATE_INVALID_MSG" },
		memberId: { reqMsg: "REGISTER.ERROR.MEMBERSHIPID_REQ_MSG", invalidMsg: "REGISTER.ERROR.MEMBERSHIPID_INVALID_MSG" },
		address: { reqMsg: "REGISTER.ERROR.ADDRESS_REQ_MSG", invalidMsg: "REGISTER.ERROR.ADDRESS_INVALID_MSG" },
		city: { reqMsg: "REGISTER.ERROR.CITY_REQ_MSG", invalidMsg: "REGISTER.ERROR.CITY_INVALID_MSG" },
		password: { reqMsg: "REGISTER.ERROR.PASS_REQ_MSG", invalidMsg: "REGISTER.ERROR.PASS_INVALID_MSG" },
		passwordConfirm: { reqMsg: "REGISTER.ERROR.PASS_REQ_MSG", invalidMsg: "REGISTER.ERROR.PASS_INVALID_MSG" },
		zip: { reqMsg: "REGISTER.ERROR.ZIP_REQ_MSG", invalidMsg: "REGISTER.ERROR.ZIP_INVALID_MSG" },
		cellPhone: { invalidMsg: "REGISTER.ERROR.CELL_INVALID_MSG" },
	};

	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		password: "",
		passwordConfirm: "",
		companyName: "",
		address: "",
		suite: "",
		country: "",
		city: "",
		state: "",
		zip: "",
		birth: "",
		cellPhone: "",
		phoneNo: "",
		latitude: 0,
		longitude: 0,
		additionalInfo: {
			visitorId: "",
			requestId: "",
			agree_to_terms: false,
			primaryDistributor: "",
			primaryDistributorInOthers: "",
			secondryDistributor: "",
			secondryDistributorInOthers: "",
			sourceFrom: "",
			purchaseByProduct: "",
			language: selected_lang,
			domain: window.location.origin,
		},
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({
		firstName: "",
		lastName: "",
		email: "",
		confirmEmail: "",
		password: "",
		passwordConfirm: "",
		companyName: "",
		address: "",
		suite: "",
		country: "",
		city: "",
		state: "",
		zip: "",
		birth: "",
		cellPhone: "",
		phoneNo: "",
		agree_to_terms: "",
		primaryDistributor: "",
		primaryDistributorInOthers: "",
		secondryDistributor: "",
		secondryDistributorInOthers: "",
		sourceFrom: "",
		purchaseByProduct: "",
	});
	useEffect(() => {
		let items = {};
		let oldState = { ...data, password: "", passwordConfirm: "" };
		setData(oldState);
		items.password = confige.uiConfig.passwordRule;
		items.passwordConfirm = confige.uiConfig.passwordRule;
		if (confige.campaignState === "partialExpired" || confige.campaignState === "expired" || confige.campaignState === "submissionExpired" || confige.submissionEnded === "submissionExpired") {
			history.push(`/`);
			toast.error(t("COMMON.PARTIAL_EXPIRED_MSG"));
		}
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
				if (element.name === "email") {
					items["confirmEmail"] = element.validation;
				}
			});
			if (confige.fingerprinting) {
				if (confige.fingerprinting.enable) {
					const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
					// Get the visitor identifier when you need it.
					fpPromise
						.then((fp) => fp.get())
						.then((result) => {
							let fdata = JSON.parse(JSON.stringify(data));
							fdata.additionalInfo.visitorId = result.visitorId;
							fdata.additionalInfo.requestId = result.requestId;
							setData(fdata);
						});
				}
			}
		}
		if (confige.captchaConfig) {
			if (confige.captchaConfig.registrationCaptcha) {
				//setCaptchaEnable(true);
			}
		}
		setValidation(items);
		// eslint-disable-next-line
	}, [confige, confige.uiConfig]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;

		if (name === "memberId") {
			if (isNaN(value) || value.indexOf(".") >= 0) {
				return false;
			}
		}
		if (name === "cellPhone") {
			if (isNaN(value) || value.indexOf(".") >= 0) {
				return false;
			}
			if (value.length >= 1 && value.length < 10) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CELL_INVALID_MSG");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
		}
		if (name === "zip") {
			let format = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
			if (value.length === 0) {
				errorMessage = errorMessages.zip.reqMsg;
				check = true;
			} else if (!format.test(value)) {
				errorMessage = errorMessages.zip.invalidMsg;
				check = true;
			}
			setError({ ...error, [name]: errorMessage });
		}
		if (name === "agree_to_opt") {
			let d = JSON.parse(JSON.stringify(data));
			d.additionalInfo.agree_to_opt_in = e.target.checked;
			setData(d);
			return true;
		}

		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.MIN_LENGTH", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.MAX_LENGTH", { count: validationArray[key] });
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.NOT_EMPTY");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("REGISTER.ERROR.EMAIL_REQ_MSG");
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.NOT_CONTAINS_NUMBER");
				setError({ ...error, [name]: errorMessage });
				check = true;
			} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CONTAINS_NUMBER");
				setError({ ...error, [name]: errorMessage });
				check = true;
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captcha"] = "";
		}
		setError(errorArray);
	}, [captchaValue]);

	const onSubmit = async () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			for (let key in validationArray) {
				if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
					errorMessage = errorMessages.email ? errorMessages.email.invalidMsg : t("REGISTER.ERROR.EMAIL_REQ_MSG");
				} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.MIN_LENGTH", { count: validationArray[key] });
				} else if (key === "maxLength" && value.length > validationArray[key]) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.MAX_LENGTH", { count: validationArray[key] });
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && !validationArray[key] && value.search(/[0-9]/) >= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.NOT_CONTAINS_NUMBER");
				} else if (key === "hasNumeric" && (name === "firstName" || name === "lastName") && validationArray[key] && value.search(/[0-9]/) <= 0) {
					errorMessage = errorMessages[name] ? errorMessages[name].invalidMsg : t("REGISTER.ERROR.CONTAINS_NUMBER");
				} else if (key === "mandatory" && validationArray[key] && !value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.NOT_EMPTY");
				}
				if (name === "zip") {
					let format = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
					if (value.length === 0) {
						errorMessage = errorMessages.zip.reqMsg;
					} else if (!format.test(value)) {
						errorMessage = errorMessages.zip.invalidMsg;
					}
				}
			}
			errorArray[name] = errorMessage;

			if (name === "confirmEmail") {
				if (data["email"] !== data["confirmEmail"]) {
					errorMessage = t("REGISTER.ERROR.EMAIL_MATCH");
				}
				errorArray["confirmEmail"] = errorMessage;
			}
			if (name === "passwordConfirm") {
				if (data["password"] !== data["passwordConfirm"]) {
					errorMessage = t("REGISTER.ERROR.PASS_MATCH");
				}
				errorArray["passwordConfirm"] = errorMessage;
			}
		}
		if (!captchaValue && captchaEnable) {
			errorArray["captcha"] = "REGISTER.ERROR.CAPTCHA_VAL";
		}
		if (!data.additionalInfo.agree_to_terms) {
			errorArray["agree_to_terms"] = "REGISTER.ERROR.TNC_CHECKBOX";
		}
		setError(errorArray);
		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!captchaValue && captchaEnable) {
			return false;
		}

		const newData = { ...data };
		if (captchaValue !== "none") {
			newData["captchaVal"] = captchaValue;
		}
		if (!check) {
			if (newData.birth) {
				let datevalue = new Date(newData.birth);
				const dd = String(datevalue.getDate()).padStart(2, "0");
				const mm = String(datevalue.getMonth() + 1).padStart(2, "0");
				const yyyy = datevalue.getFullYear();
				const formatedDate = yyyy + "-" + mm + "-" + dd;
				newData.birth = formatedDate;
			}
			if (!newData.hasOwnProperty("additionalInfo")) {
				newData["additionalInfo"] = {};
			}
			if (promoCode) {
				newData["additionalInfo"]["promoCode"] = promoCode;
			}
			if (source) {
				newData["additionalInfo"]["source"] = source;
			}
			newData["additionalInfo"]["language"] = i18n.language.toUpperCase();

			setLoading(true);
			// props.setLoading(true);
			try {
				let signupCall = true;
				if (confige.usePreRegistration) {
					signupCall = false;
					let preRegistrationResp = await preRegistrationAction(newData);
					if (preRegistrationResp.data) {
						if (preRegistrationResp.data.valid) {
							signupCall = true;
							newData.additionalInfo.token = preRegistrationResp.data.token;
						} else {
							toast.error(preRegistrationResp.data.errorReason);
							setLoading(false);
							// props.setLoading(false);
						}
					} else {
						toast.error(t("COMMON.SOMTHING_WRONG"));
						setLoading(false);
						// props.setLoading(false);
					}
				}
				if (signupCall) {
					let signupResp = await signup(newData, referralid)
						.then((resp) => {
							setLoading(false);
							//setViewWelcomeScreen(true);
						})
						.catch((err) => {
							if (Array.isArray(err)) {
								err.map((item) => {
									if (item?.errorCode?.length > 0) {
										toast.error(t(`REGISTER.ERROR.${item.errorCode}`));
									} else {
										toast.error(item.message);
									}
								});
							} else {
								toast.error(t("COMMON.SOMTHING_WRONG"));
							}
							window.scroll({
								top: 0,
								left: 100,
								behavior: "smooth",
							});
							setLoading(false);
							// props.setLoading(false);
						});
				}
			} catch (error) {
				console.log("errror", error);
				setLoading(false);
				// props.setLoading(false);
				if (Array.isArray(error)) {
					error.map((item) => {
						if (item?.errorCode?.length > 0) {
							toast.error(t(`REGISTER.ERROR.${item.errorCode}`));
						} else {
							toast.error(item.message);
						}
					});
				} else {
					toast.error(t("COMMON.SOMTHING_WRONG"));
				}
			}
		}
	};
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());
	const isStepOptional = (step) => {
		return step === -1;
	};
	const isStepSkipped = (step) => {
		return skipped.has(step);
	};
	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		if (activeStep + 1 === 1) {
			onSubmit();
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
			setSkipped(newSkipped);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<div className="container-fluid">
			<div className="row justify-content-center">
				<div className="col-12 m-0 p-0">
					<img src="/public/images/Login_banner.png" alt="login-banner" className="d-block w-100" />
				</div>
			</div>
			<div id="enternow" className="register-page">
				<div className="p-0 col-sm-9">
					<div className="my-5 d-flex flex-column align-items-center justify-content-center">
						<h1 className="h2 custom-home-h1 d-inline px-1">REGISTRATION FORM</h1>
						<p className="mt-4 fs-18 light-blue text-center">
							COMPLETE THE REGISTRATION FORM BELOW <br />
							<br /> Be aware that at this time this program is exclusively for US and Canada based professional contractors.
						</p>
					</div>
					<Box sx={{ width: "100%" }}>
						<Stepper activeStep={activeStep}>
							{steps.map((label, index) => {
								const stepProps = {};
								const labelProps = {};
								if (isStepOptional(index)) {
									labelProps.optional = <Typography variant="caption">Optional</Typography>;
								}
								if (isStepSkipped(index)) {
									stepProps.completed = false;
								}
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>{label}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						{activeStep === steps.length ? (
							<React.Fragment>
								<Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
								<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
									<Box sx={{ flex: "1 1 auto" }} />
									<Button onClick={handleReset}>Reset</Button>
								</Box>
							</React.Fragment>
						) : (
							<React.Fragment>
								{activeStep === 0 && (
									<>
										<Row className="my-3 py-3">
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-firstName-input"
													name="firstName"
													onChange={handleChange}
													value={data.firstName}
													error={error?.firstName ? true : false}
													helperText={error?.firstName || ""}
													id={"firstName"}
													label={"First Name"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-lastName-input"
													name="lastName"
													onChange={handleChange}
													value={data.lastName}
													error={error?.lastName ? true : false}
													helperText={error?.lastName || ""}
													id={"lastName"}
													label={"Last Name"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="email"
													data-test="sign-in-email-input"
													name="email"
													onChange={handleChange}
													value={data.email}
													error={error?.email ? true : false}
													helperText={error?.email || ""}
													id={"email"}
													label={"Email"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="email"
													data-test="sign-in-confirmEmail-input"
													name="confirmEmail"
													onChange={handleChange}
													value={data.confirmEmail}
													error={error?.confirmEmail ? true : false}
													helperText={error?.confirmEmail || ""}
													id={"confirmEmail"}
													label={"Confirm Email"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="password"
													data-test="sign-in-password-input"
													name="password"
													onChange={handleChange}
													value={data.password}
													error={error?.password ? true : false}
													helperText={error?.password || ""}
													id={"password"}
													label={"Password"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="password"
													data-test="sign-in-confirmPassword-input"
													name="confirmPassword"
													onChange={handleChange}
													value={data.confirmPassword}
													error={error?.confirmPassword ? true : false}
													helperText={error?.confirmPassword || ""}
													id={"confirmPassword"}
													label={"Confirm Password"}
												/>
											</div>
										</Row>
									</>
								)}
								{activeStep === 1 && (
									<>
										<Row className="my-3 py-3">
											<div className="form-group col-sm-12 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-companyName-input"
													name="companyName"
													onChange={handleChange}
													value={data.companyName}
													error={error?.companyName ? true : false}
													helperText={error?.companyName || ""}
													id={"companyName"}
													label={"Company Name"}
												/>
											</div>
											<div className="form-group col-sm-12 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-address-input"
													name="address"
													onChange={handleChange}
													value={data.address}
													error={error?.address ? true : false}
													helperText={error?.address || ""}
													id={"address"}
													label={"Address"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-aptNumber-input"
													name="aptNumber"
													onChange={handleChange}
													value={data.aptNumber}
													error={error?.aptNumber ? true : false}
													helperText={error?.aptNumber || ""}
													id={"aptNumber"}
													label={"APT/SUITE Number"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-country-input"
													name="country"
													onChange={handleChange}
													value={data.country}
													error={error?.country ? true : false}
													helperText={error?.country || ""}
													id={"country"}
													label={"Country"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="text"
													data-test="sign-in-city-input"
													name="city"
													onChange={handleChange}
													value={data.city}
													error={error?.city ? true : false}
													helperText={error?.city || ""}
													id={"city"}
													label={"City"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<div className="row">
													<div className="col-sm-6">
														<TextField
															InputProps={{
																autoComplete: "true",
															}}
															variant="outlined"
															fullWidth
															type="text"
															data-test="sign-in-state-input"
															name="state"
															onChange={handleChange}
															value={data.state}
															error={error?.state ? true : false}
															helperText={error?.state || ""}
															id={"state"}
															label={"State/Province"}
														/>
													</div>
													<div className="col-sm-6">
														<TextField
															InputProps={{
																autoComplete: "true",
															}}
															variant="outlined"
															fullWidth
															type="text"
															data-test="sign-in-zip-input"
															name="zip"
															onChange={handleChange}
															value={data.zip}
															error={error?.zip ? true : false}
															helperText={error?.zip || ""}
															id={"zip"}
															label={"Zip/Postal Code"}
														/>
													</div>
												</div>
											</div>
											<div className="form-group col-sm-12 my-3">
												<TextField
													InputProps={{
														autoComplete: "true",
													}}
													variant="outlined"
													fullWidth
													type="date"
													data-test="sign-in-birth-input"
													name="birth"
													onChange={handleChange}
													value={data.birth}
													error={error?.birth ? true : false}
													helperText={error?.birth || "NOTE : We are collecting this information so that we can wish you a happy birthday (Age must be 18+)."}
													id={"birth"}
													label={"Date of Birth"}
												/>
											</div>
											<div className="form-group col-sm-6 my-3">
												<FormControl fullWidth>
													<InputLabel id="distributor-select-label">Select Primary Distributor Name</InputLabel>
													{/* <FormHelperText>Select Primary Distributor from list Provider</FormHelperText> */}
													<Select labelId="distributor-select-label" id="distributor-select" name="primaryDistributor" error={error?.primaryDistributor ? true : false} helperText={error?.primaryDistributor || ""} value={data?.primaryDistributor} label="Select Distributor Name" onChange={handleChange}>
														<MenuItem value={10}>Ten</MenuItem>
														<MenuItem value={20}>Twenty</MenuItem>
														<MenuItem value={30}>Thirty</MenuItem>
													</Select>
												</FormControl>
											</div>
											<div className="form-group col-sm-6 my-3">
												<FormControl fullWidth>
													<InputLabel id="distributor2-select-label">Select Distributor Name</InputLabel>
													{/* <FormHelperText>Select Primary Distributor from list Provider</FormHelperText> */}
													<Select labelId="distributor2-select-label" id="distributor2-select" name="secondryDistributor" error={error?.secondryDistributor ? true : false} helperText={error?.secondryDistributor || ""} value={data?.secondryDistributor} label="Select Distributor Name" onChange={handleChange}>
														<MenuItem value={10}>Ten</MenuItem>
														<MenuItem value={20}>Twenty</MenuItem>
														<MenuItem value={30}>Thirty</MenuItem>
													</Select>
												</FormControl>
											</div>
											<div className="form-group col-sm-6 my-3">
												<FormControl fullWidth>
													<InputLabel id="Where did you hear about us">Where did you hear about us</InputLabel>
													{/* <FormHelperText>Select Primary Distributor from list Provider</FormHelperText> */}
													<Select labelId="Where did you hear about us" id="Where did you hear about us" name="sourceFrom" error={error?.sourceFrom ? true : false} helperText={error?.sourceFrom || ""} value={data?.sourceFrom} label="Where did you hear about us" onChange={handleChange}>
														<MenuItem value={10}>Ten</MenuItem>
														<MenuItem value={20}>Twenty</MenuItem>
														<MenuItem value={30}>Thirty</MenuItem>
													</Select>
												</FormControl>
											</div>
											<div className="form-group col-sm-12 my-3">
												<FormControl>
													<FormLabel id="demo-radio-buttons-group-label">SELECT YOUR MAIN TYPE OF PURCHASE BY PRODUCT</FormLabel>
													<RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="purchaseByProduct" error={error?.purchaseByProduct ? true : false} helperText={error?.purchaseByProduct || ""} value={data?.purchaseByProduct}>
														<FormControlLabel value="female" control={<Radio />} label="IRRIGATION (TORO/IRRITROL) AND LIGHTING (UNIQUE LIGHTING)" />
														<FormControlLabel value="male" control={<Radio />} label="TORO PARTS" />
													</RadioGroup>
												</FormControl>
											</div>
											<div className="form-group col-sm-12 my-3">
												<FormGroup>
													<FormControlLabel name="terms" error={error?.terms ? true : false} helperText={error?.terms || ""} value={data?.terms} onChange={handleChange} control={<Checkbox />} label="I AGREE TO THE TERMS AND CONDITIONS" />
												</FormGroup>
											</div>
											{captchaEnable && (
												<div className="form-group col-sm-12 my-3">
													<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
														Google captcha
													</label>
													<div className="col-sm-12">
														<Captcha align={"left"} reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
														<p className="input-error">{t(error.captcha)}</p>
													</div>
												</div>
											)}
											<div className="form-group col-sm-12 my-3">
												<FormHelperText>Please add our email, support@tororewards.com, to your secure mailing list to avoid delays. If our emails aren't in your inbox, they could be in your spam, junk or promotion folder, even if you have received emails from us before! For any questions, comments, or concerns, please contact us. </FormHelperText>
											</div>
										</Row>
									</>
								)}
								<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
									{activeStep !== 0 && (
										<button className="btn btn-primary login-btn  mt-0 me-5 uppercase" onClick={handleBack} sx={{ mr: 1 }}>
											Back
										</button>
									)}
									<button
										className="btn btn-primary login-btn  mt-0 uppercase"
										onClick={() => {
											handleNext();
										}}>
										{activeStep === steps.length - 1 ? "Finish" : "Next"}
									</button>
								</Box>
							</React.Fragment>
						)}
					</Box>
				</div>
			</div>
		</div>
	);
};

// export default connect(null, { signup, login })(IsLoadingHOC(Signup, "Wait ....."));
export default IsLoadingHOC(Signup, "Wait .....");

import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { downloadRewardsAction } from "../../redux/actions";
import { useParams, Navigate } from "react-router-dom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { toast } from "react-toastify";

const RewardDownload = (props) => {
	const { id, filename } = useParams();
	const { t } = useTranslation();
	const [isValidRequest, setIsValidRequest] = useState(true);
	useEffect(() => {
		if (!id || !filename) {
			setIsValidRequest(false);
		}
	}, [id, filename]);
	const downloadRewardsHandler = (e) => {
		props.setLoading(true);
		downloadRewardsAction(id, filename)
			.then(async (response) => {
				if (response.data && response.httpCode === 200) {
					let signedurl = response.data;
					await downloadFileUsingSignedUrl(signedurl);
				} else {
					toast.error(`${t("Error")}: ${t("Something went wrong")}!`);
					props.setLoading(false);
				}
			})
			.catch((err) => {
				toast.error(`${t("Error")}: ${t("Something went wrong")}!`);
				props.setLoading(false);
			});
	};
	const downloadFileUsingSignedUrl = async (url) => {
		try {
			let anchor = document.createElement("a");
			let fileData = await fetch(url, { method: "GET" });
			const fileBlobData = await fileData.blob();
			let objectUrl = window.URL.createObjectURL(fileBlobData);
			anchor.href = objectUrl;
			anchor.target = "new";
			anchor.download = filename;
			anchor.click();
			window.URL.revokeObjectURL(objectUrl);
			toast.success(`${t("Download successfully")}.`);
			props.setLoading(false);
		} catch (err) {
			toast.error(`${t("Error")}: ${t("Something went wrong")}!`);
			props.setLoading(false);
		}
	};
	if (!isValidRequest) {
		return <Navigate to="\" />;
	} else {
		return (
			<div className="earnpoint-page">
				<Container fluid>
					<h2 className="alignCenter thankuh2">
						<Button variant="dark" onClick={downloadRewardsHandler}>
							<Trans>Download</Trans>
						</Button>
					</h2>
					<br />
				</Container>
			</div>
		);
	}
};
export default IsLoadingHOC(RewardDownload, "Loading....");

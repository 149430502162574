import React, { useEffect, useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import IsLoadingHOC from "../components/IsLoadingHOC";

const CarouselCustom = (props) => {
	const [data, setData] = useState([]);
	useEffect(async () => {
		try {
			const apiResp = await fetch("/public/common.json");
			const response = await apiResp.json();
			if (response) {
				setData(response?.carouselData || []);
			} else {
				setData([]);
			}
			props.setLoading(false);
		} catch (error) {
			props.setLoading(false);
			setData([]);
			console.log(error);
		}
	}, []);
	return (
		<Container className="" fluid>
			<div className="row justify-content-center" id="home">
				<Carousel className="col-12 m-0 p-0" fade interval={3000}>
					{data.map((v, i) => {
						return (
							<Carousel.Item style={{ cursor: v.action === "" ? "-webkit-grab" : "pointer" }}>
								<a href={v.action !== "" ? v.action : false} target="new">
									<img className="d-block w-100  banner-img desktop-hide" src={v.desktopImgUrl} alt={v.altText} />
									<img className="d-block w-100 mobile-hide banner-img" src={v.mobileImgUrl} alt={v.altText} />
								</a>
							</Carousel.Item>
						);
					})}
				</Carousel>
			</div>
		</Container>
	);
};
export default IsLoadingHOC(CarouselCustom, "");

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { resetPassword, resetPasswordTokenValidationCheckAction } from "../../redux/actions";
import { useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormControl, Input, InputLabel } from "@mui/material";
import Required from "../../components/Required";
const ResetPassword = ({ setLoading, resetPassword, location }) => {
	const { t } = useTranslation();
	const history = useNavigate();
	const confige = useSelector((state) => state.app.config);
	const [passwordConfirm, passwordConfirmSet] = useState("");
	const [password, setPassword] = useState("");
	const [isValidToken, setIsValidToken] = useState(true);
	const [errors, setErrors] = useState({ passwordConfirm: "", password: "" });
	const [token, setToken] = useState("");
	const [passwordValidation, setPasswordValidation] = useState({});
	const errorMessages = {
		password: { reqMsg: t("REGISTER.ERROR.PASS_REQ_MSG"), invalidMsg: t("REGISTER.ERROR.PASS_INVALID_MSG") },
		passwordConfirm: { reqMsg: t("REGISTER.ERROR.PASS_REQ_MSG"), invalidMsg: t("COM_PASS_INVALID_MSG") },
	};

	useEffect(() => {
		if (confige.uiConfig) {
			if (confige.uiConfig.passwordRule) {
				confige.uiConfig.passwordRule.mandatory = true;
				confige.uiConfig.passwordRule.hasNumeric = true;
				setPasswordValidation(confige.uiConfig.passwordRule);
			}
		}
	}, [confige, confige.uiConfig]);
	const validatePassword = (value, validationArray) => {
		let isValidPassword = true;
		for (let key in validationArray) {
			if (key === "minLength") {
				if (value.length < validationArray[key] && value.length !== 0) {
					setErrors({ ...errors, password: errorMessages.password.invalidMsg });
					isValidPassword = false;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					setErrors({ ...errors, password: errorMessages.password.invalidMsg });
					isValidPassword = false;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					setErrors({ ...errors, password: errorMessages.password.reqMsg });
					isValidPassword = false;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					setErrors({ ...errors, password: errorMessages.password.invalidMsg });
					isValidPassword = false;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					setErrors({ ...errors, password: errorMessages.password.invalidMsg });
					isValidPassword = false;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				// eslint-disable-next-line
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					setErrors({ ...errors, password: errorMessages.password.invalidMsg });
					isValidPassword = false;
				}
			}
		}
		return isValidPassword;
	};
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const token = query.get("token");
		if (!token) {
			setIsValidToken(false);
		} else {
			setLoading(true);
			resetPasswordTokenValidationCheckAction(token, {})
				.then((data) => {
					if (data.httpCode === 404) {
						setIsValidToken(false);
						toast.error(t("CHANG_PASS_LINK_EXP"));
					}
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					setIsValidToken(false);
				});
		}
		setToken(token);
		// eslint-disable-next-line
	}, []);

	const onChangeHandle = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		for (let key in passwordValidation) {
			if (key === "minLength") {
				if (value.length < passwordValidation[key] && value.length !== 0) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MIN_LENGTH", {
								count: passwordValidation[key],
						  });
				}
			}
			if (key === "maxLength") {
				if (value.length > passwordValidation[key]) {
					errorMessage = errorMessages[name]
						? errorMessages[name].invalidMsg
						: t("REGISTER.ERROR.MAX_LENGTH", {
								count: passwordValidation[key],
						  });
				}
			}
			if (key === "mandatory" && passwordValidation[key]) {
				if (!value.length) {
					errorMessage = errorMessages[name] ? errorMessages[name].reqMsg : t("REGISTER.ERROR.PASS_REQ_MSG");
				}
			}
			if (key === "hasNumeric" && passwordValidation[key] && value.search(/[0-9]/) < 0) {
				errorMessage = errorMessages[name].invalidMsg;
			}
			if (key === "hasAlpha" && passwordValidation[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
				errorMessage = errorMessages[name].invalidMsg;
			}
			if (key === "upperCaseReq" && passwordValidation[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = errorMessages[name].invalidMsg;
				}
			}
			if (key === "lowerCaseReq" && passwordValidation[key]) {
				if (value.search(/[a-z]/) < 0 && value.length !== 0) {
					errorMessage = errorMessages[name].invalidMsg;
				}
			}
			if (key === "specialCharReq" && passwordValidation[key]) {
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = errorMessages[name].invalidMsg;
				}
			}
		}

		setErrors({ ...errors, [name]: errorMessage });
		if (name === "password") {
			setPassword(value);
		}
		if (name === "passwordConfirm") {
			passwordConfirmSet(value);
		}
	};

	const handleError = () => {
		let value = false;
		const error = {};
		if (password !== passwordConfirm) {
			error.passwordConfirm = t("PASS_MATCH");
			value = true;
		}
		return { value, error };
	};

	const onSubmit = () => {
		const { value, error } = handleError();
		if (value) {
			setErrors({ ...errors, ...error });
			return false;
		}
		if (validatePassword(password, passwordValidation)) {
			setLoading(true);
			resetPassword({ token, password })
				.then((response) => {
					setLoading(false);
					toast.success(t("CHANG_PASS_SUCCESS"));
					history.push("/");
				})
				.catch((error) => {
					console.log("err", error);
					toast.error(t("CHANG_PASS_USE_MSG"));
					error.map((item) => {
						//toast.error(item.message);
					});
					setLoading(false);
				});
		}
	};
	if (!isValidToken) {
		return <Navigate to={"/"} />;
	} else {
		return (
			<div id="enternow" className="register-page signup-form">
				<div className="p-0 col-sm-9">
					<h1 className="mt-4 styleColor"> {`${t("Reset")} ${t("password")}`}</h1>
					<div>
						<p className="text-center">
							<Required />
							{t("REGISTER.sub_heading1")}
						</p>
					</div>
					<div className="mb-0 bg-blue px-1 h-20"></div>
					<div className="px-2 py-3 pb-5 mt-0 bg-grey">
						<div className="container-fluid">
							<form role="Form" aria-label="Rest Password">
								<div className="row justify-content-center">
									<div className="form-group mt-2 col-md-6">
										<FormControl fullWidth variant="outlined">
											<InputLabel fullWidth htmlFor="password" className="reg-input-lable">
												<Required />
												{t("password")}
											</InputLabel>
											<Input autoComplete="true" disableUnderline fullWidth type="password" name="password" id="password" onChange={onChangeHandle} aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-email" }} />
											<div className="col-12 input-error">{errors.password}</div>
										</FormControl>
									</div>
								</div>
								<div className="row justify-content-center">
									<div className="form-group mt-2 col-md-6">
										<FormControl fullWidth variant="outlined">
											<InputLabel fullWidth htmlFor="passwordConfirm" className="reg-input-lable">
												<Required />
												{t("confirm password")}
											</InputLabel>
											<Input autoComplete="true" disableUnderline fullWidth type="password" name="passwordConfirm" id="passwordConfirm" onChange={onChangeHandle} aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-email" }} />
											<div className="col-12 input-error">{errors.passwordConfirm}</div>
										</FormControl>
									</div>
								</div>
								<div className="row justify-content-center">
									<div className="px-md-5">
										<div className="mt-2 text-center w-100">
											<button onClick={onSubmit} data-test="reset-password-submit" type="button" className="btn btn-primary">
												{t("SAVE")}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default connect(null, { resetPassword })(IsLoadingHOC(ResetPassword, "Wait ...."));

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IsLoadingHOC from "./../components/IsLoadingHOC";
import { pointHistoryGet } from "../redux/actions";
import { Container, Table, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import "../assets/css/pagination.scss";

const PointHistory = (props) => {
	const { t } = useTranslation();
	const setLoading = props.setLoading;
	const [offset, setOffset] = useState(0);
	const [data, setData] = useState([]);
	const [perPage] = useState(10);
	const [pageCount, setPageCount] = useState(0);
	const [totalPoint, setTotalPoint] = useState(0);
	const getData = async () => {
		props
			.pointHistoryGet()
			.then((response) => {
				if (response["data"]) {
					const resData = response.data.content;
					let totp = 0;
					resData.forEach((val) => {
						if (val.ledger === "credit") {
							totp += val.points;
						} else {
							totp -= val.points;
						}
					});
					setTotalPoint(totp);
					setPageCount(Math.ceil(resData.length / perPage));

					const slice = resData.slice(offset, offset + perPage);
					const postData = slice.map((item, index) => {
						let itemDate = new Date(item.date);
						let newDate = itemDate.toLocaleDateString();
						return { description: item.description, newDate: newDate, ledger: item.ledger === "credit" ? "+" : "-", points: item.points };
					});
					setData(postData);
				}
				setLoading(false);
			})
			.catch((ex) => {
				console.log("error in Point List Api", ex);
				setLoading(false);
			});
	};

	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		setOffset(selectedPage * perPage);
	};

	useEffect(() => {
		setLoading(true);
		getData();
		//eslint-disable-next-line
	}, [offset]);

	return (
		<Container>
			<div className="portal-history">
				<Row xs={1} md={1} lg={1}>
				<h1 className="mb-5 mt-5">{t("Manage Your Account")}</h1>
					{/* <h2 className="h2-title text-center">
						<Trans>My Rewards</Trans>
					</h2> */}
				</Row>
				<Row className="mt-3 mb-5 page-tab_nav">
					<Col className="text-left">
						<Link id="href-point-history" to="/points-history" className="active-page">
							{t("Point History")}
						</Link>
					</Col>
					<Col className="text-center">
						<Link id="href-my-rewards" to="/my-rewards">
							{t("My Rewards")}
						</Link>
					</Col>
					<Col className="text-right">
						<Link id="href-profile" to="/profile">
							{t("Profile")}
						</Link>
					</Col>
				</Row>
				<Row>
			
					{/* <h1 className="mb-5 mt-4">Manage Your Account</h1>
					<div className="point-history-label">
						<h4>
							{" "}
							<Trans>Point History</Trans>{" "}
						</h4>
						<span>
							<b>
								<Trans>Total Point</Trans>
							</b>{" "}
							{totalPoint}
						</span>
					</div> */}
					<div className="mya_table col-md-12">
						<Table>
							<thead>
								<tr>
									<th width="50%">
										<Trans>Description</Trans>
									</th>
									<th width="30%" className="text-center">
										<Trans>Date</Trans>
									</th>
									<th width="20%" className="text-center">
										<Trans>Points</Trans>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? (
									<>
										{data.map((row, i) => {
											return (
												<tr key={i}>
													<td>{row.description}</td>
													<td className="text-center">{row.newDate}</td>
													<td className="text-center">
														{row.ledger}
														{row.points}
													</td>
												</tr>
											);
										})}
									</>
								) : (
									<tr>
										<td colSpan="3">
											<center>
												<Trans>No point history found</Trans> !
											</center>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
					<ReactPaginate previousLabel={t("Prev")} nextLabel={t("Next")} breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={"pagination"} subContainerClassName={"pages pagination"} activeClassName={"active"} />
				</Row>
			</div>
		</Container>
	);
};

export default connect(null, { pointHistoryGet })(IsLoadingHOC(PointHistory));
